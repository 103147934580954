module.exports = [{
      plugin: require('../node_modules/@shapeable/gatsby-theme-shapeable/node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","icon":"./src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"35420c18fd034ef720badc40a2b205ab"},
    },{
      plugin: require('../node_modules/@shapeable/gatsby-theme-shapeable/gatsby-browser.js'),
      options: {"plugins":[],"siteMetadata":{"siteUrl":"https://prf.shapeable.ai"}},
    }]
